var loader = document.querySelector(".loader");
var main = document.querySelector(".main");

window.onload = function() {
    setTimeout(function () {
        loader.classList.add("loaded");
    }, 1000);
    setTimeout(function () {
        main.classList.add("loaded");
    }, 1400);
};